import React, { useEffect, useState } from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';
import ViewModal from '../../CommonModals/viewModal';
import { useParams } from 'react-router';
// config file
const Activities = (props) => {
	
	const [type, setType] = useState('Activities');
	const [rolePermission, setRolePermission] = useState('Activities');

	const [viewSelectedRecord, setViewSelectedRecord] = useState(
		window.location.href.includes("view")
	);
	const [contextObj, setContextObj] = useState()

	const params = useParams();
	const getDerivedStateFromProps = (props, state) => {
		let storeData = store.getState()
		let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
		return { languageData: languageData };
	}
	
	const isEditPermission = rolePermission?.type == "Edit" ? true : false;

	const actionTypes = [
		{
			name: "Delete",
			options: [
				{
					label: "Delete",
					value: "Delete",
					show: rolePermission?.actions?.includes("Delete") ? true : false,
					multiple: true,
				},
			],
		},
	]
	
	useEffect(() => {
		let screenPermissions = RolePermissions.screenPermissions('Activities');
		if (screenPermissions) {		
			setRolePermission(screenPermissions);
		}

		if (params.id && !viewSelectedRecord) {
			localStorage.setItem('contextObj', JSON.stringify({ contextId: JSON.parse(params.id) }))
			setContextObj({ contextId: JSON.parse(params.id) })
		} else if (viewSelectedRecord && localStorage.getItem('contextObj')) {
			setContextObj(JSON.parse(localStorage.getItem('contextObj')))
		} else {
			setContextObj({})
		}

		return () => {
			localStorage.removeItem('contextObj')
		}
	}, [])

	const getTableFields = () => {	
		let data =[
			{
				textAlign: "center",
				width: 47,
				field: "Sno",
				header: "Sno",
				label: "Sno",
				filter: false,
				sortable: false,
				mobile: true,
				placeholder: "Search",
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "center",
				show: true,
				mobile: true,
				width: 80,
				field: "created",
				label: "Created",
				fieldType: "Date",
				type: 'date',
				dateFormat: config.monthDateFormat,
				header: "Created Date",
				// filter: true,
				sortable: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "context",
				mobile: true,
				header: "Context",
				label: "Context",
				filter: true,
				fieldType: "Link",
				sortable: true,
				show: true,
				textCapitalize: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "contextType",
				mobile: true,
				label: "Context Type",
				header: "Context Type",
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "email",
				mobile: true,
				label: "Email",
				header: "Email",
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				show: true,
				textAlign: "left",
				width: 80,
				mobile: true,
				field: "description",
				header: "Description",
				filter: true,
				label: "Description",
				sortable: true,
				fieldType: 'viewTemplate',
				dependentField: "newObject",
				includeDependent: true,
				textCapitalize: true,
				displayInSettings: true,
				style: {
					maxWidth: '500px',
					display: 'block',
					overflow: 'hidden',
					whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
					textOverflow: 'ellipsis',
				}
			},
			{
				textAlign: "left",
				width: 80,
				field: "ipAddress",
				label: "Ip Address",
				mobile: true,
				header: 'Ip Address',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "deviceType",
				label: "Device Type",
				mobile: true,
				header: 'Device Type',
				filter: true,
				sortable: true,
				show: true,
				className:'text-capitalize-first-letter',
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: "browserName",
				label: "Browser Name",
				mobile: true,
				header: 'Browser Name',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				textAlign: "left",
				width: 80,
				field: 'osName',
				label: "Os Name",
				mobile: true,
				header: 'Os Name',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
				"type": "dropDown",
				"fieldType": "dropDown",
				"filterElement": [
					{
						"label": "Windows",
						"value": "Windows",
						"color": "primary"
					},
					{
						"label": "Linux",
						"value": "Linux",
						"color": "primary"
					}
				],
				"options": [
					{
						"label": "Windows",
						"value": "Windows",
						"color": "primary"
					},
					{
						"label": "Linux",
						"value": "Linux",
						"color": "primary"
					}
				]
			},
			{
				textAlign: "left",
				width: 80,
				field: 'osVersion',
				label: "Os Version",
				mobile: true,
				header: 'Os Version',
				filter: false,
				sortable: true,
				show: true,
				displayInSettings: true,
			},			]

		return data;
	};


	const getFormFields = () => {
		return ([
			{
				'show': false,
				"value": "",
				"type": "text",
				"name": "firstName",
				"label": "First Name",
				"id": "firstName",
				"placeholder": "First Name",
				"required": true
			},
			
			
		]);
	}

		return (
			<span>
			{contextObj && <DataTables
					getTableFields={getTableFields}
					formFields={getFormFields}
					actionsTypes={actionTypes}
					exportRequried={false}
					printRequried={false}
					addRequried={isEditPermission}
					editRequired={isEditPermission}
					deleteRequired={isEditPermission}
					viewRequired={isEditPermission}
					viewTemplate={true}
					settingsRequired={true}
					filterRequired={false}
					gridRequried={false}
					sample={false}
					globalSearchFieldName='activity'
					globalSearch={"Context/Email/Description"}
					type="Activities"
					displayName='Activities'
					apiResponseKey={"activities"}
					apiUrl={"activities"}
					routeTo='activities'
					displayViewOfForm='screen'
					selectedId={params.id}
					disableActionDropdown
				// params={params.id && !viewSelectedRecord  ? {contextId:JSON.parse(params.id)} : {}}
				params={contextObj}
			/>}
			</span>
		);
	
}

export default Activities;