import config from "../../../config/config";
import apiCalls from "../../../config/apiCalls";
import html2pdf from 'html2pdf.js';

//For the View & Download form
/**
 * 
 * @param {Object} rowData 
 * @param {Boolean} downloadFile 
 * @param {Boolean} noMofication 
 * @returns 
 */
export const convertHtmlToPdf = (rowData, downloadFile, noMofication) => {

    let text = '';
    let fileName = rowData?.patientId?.name ? rowData?.patientId?.name + '_' : '';
    fileName += rowData?.formNameName ? rowData?.formNameName : 'downloaded';
    fileName += '.pdf';
    const templateData = rowData.templateText || rowData.templateData;
    if (rowData.uploadFilePath && downloadFile) {

        fetch(config.imgUrl + apiCalls.forms + '/' + rowData.uploadFilePath)
            .then(response => response.blob())
            .then(blob => {
                var a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(a.href);
            });
        return;
    }
    if (templateData && templateData != "") {
        text = templateData.replaceAll(/<input[^>]*value = "([^"]*)"[^>]*style\s*=\s*"width:([^"]*)px"[^>]*>|<input[^>]*value = ""[^>]*style\s*=\s*"width:([^"]*)px"[^>]*>|<input[^>]*type="checkBox"[^>]*>|<textarea[^>]*>(.*?)<\/textarea>/g, (str, value, style, style2, textareaData) => {

            if (value || textareaData) {
                return `<span class="entered_data">  ${value || textareaData}  </span>`;
            } else if (str.includes('<textarea')) {
                return '_________________________________________________________________________________';
            } else if (str.includes('value = ""')) {
                return `<span>${'_'.repeat(style / 10)}</span>`;
            }
            else if (str.includes('true')) {
                return `<i class="pi pi-check-square text-info m-1" style=" fontSize: '1rem' "></i>`;
            }
            return `<i class="pi pi-stop text-danger m-1" style=" fontSize: '1rem' "></i>`;
        })

    } else if (rowData.formResponses && downloadFile) {
        rowData.formResponses.map((obj, i) => {
            text += `<div class="row mb-1"><div class ='col-6'>${i + 1} . ${obj.question} </div> <div class ='col-6'>- ${obj.answers}</div> </div>`
        })
    }

    //Add the Signature to PDF when we Download
    if (rowData.doctorSignature || rowData.patientSignature) {
        let signatureContent = '<div class="d-flex justify-content-between">';
        if (rowData.doctorSignature) {
            signatureContent += `<div class='mx-2'>${typeof rowData.doctorSignature === 'string' ? `<img class="mb-5" src='${rowData.doctorSignature}'/>` : '<p class="mb-5"></p>'}<p>Provider Signature</p></div>`;
        }
        if (rowData.patientSignature && !templateData.includes('<img ')) {
            signatureContent += `<div  class='mr-2'>${typeof rowData.patientSignature === 'string' ? `<img class="mb-5" src='${rowData.patientSignature}'/>` : '<p class="mb-5"></p>'}<p>Patient Signature</p></div>`
        }
        signatureContent += '</div>'
        text += signatureContent
    }

    const options = {
        margin: 10, // Set the top margin as needed
        filename: fileName,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const textChunks = text.split('<page-break>');
    let htmlContent = textChunks.map(chunk => `<div class="p-5 mt-2">${chunk}</div>`).join('<div style="page-break-before: always;"></div>');

    if (downloadFile) {
        htmlContent = htmlContent.replaceAll(config.signatureContent, '<div class="py-5 "></div>');
        html2pdf(htmlContent, options).outputPdf().then((pdf) => {
            pdf.save(options.filename);
        });
    }
    if (noMofication) {
        return text;
    }
    if (!text) {
        return;
    }
    return `<div class = 'display_form_data'>${text}</div>`;
};


/**
  * 
  * @param {Object} obj 
  * @returns 
  */
export const templateTextConverter = (obj, hospitalData = {}) => {//Convert the Text to Input fields based on the formate

    if (obj.templateText) {
        obj.templateText = obj.templateText.replaceAll(config.templateInputFieldPattern, (str) => {
            if (str == '@PatientName') {
                return `<input value = "" name='tempData' class="m-1 h-50" style="width:${240}px" id='patientName'/>`;
            } else if (str == '@PracticeName') {
                return hospitalData?.hospitalName ? `<div class="text-center fw-bold text-capitalize-first-letter">${hospitalData?.hospitalName}</div>` : '';
            } else if (str == '@PracticePhoneNumber') {
                return hospitalData?.phoneNumber ? `<span class="fw-bold">${hospitalData?.phoneNumber}</span>` : '';
            } else if (str == '@PracticeAddress') {
                return hospitalData?.fullAddressAndZip ? `<div class="text-center fw-bold text-capitalize-first-letter">${hospitalData?.fullAddressAndZip}</div>` : '';
            } else if (str == '@Date') {
                return `<input value = "" name='tempData' class="m-1 h-50" style="width:${150}px" id='date'/>`
            } else if (str == '@DOB') {
                return `<input value = "" name='tempData' class="m-1 h-50" style="width:${150}px" id='dob'/>`;
            } else if (str == `` || str == '@CheckBox') {
                return `<input name='tempData' type="checkBox" class="m-1"/>`
            } else if (str == '@PatientSignature') {
                return obj.patientSignature ? config.signatureContent : '';
            }
            else if (str == '@TextArea') {
                return `<textarea name='tempData' class="w-100"></textarea>`
            }
            return `<input value = "" name='tempData' class="m-1 h-50" style="width:${str.length * 10}px"/>`
        })
    }
    return obj;
}


/**
     * 
     * @param {String} text 
     * @returns 
     */
export const getTemplateData = async (text) => {//Add the entered Data in to Template text as the String    
    let i = -1
    let values = await document.getElementsByName("tempData");
    let src = await text.replaceAll(/<input\s*value\s*=\s*"([^"]*)"|<input\s*[^>]*checked\s*=\s*"([^"]*)"|<input\s*|<textarea [^>]*>([^"]*)<\/textarea>/g, (str) => {
        i++;
        if (values[i].type == "checkbox") {
            if (!values[i].checked) {
                return '<input ';
            }
            return '<input ' + `checked="${values[i].checked}"`;
        }
        else if (str.includes("</textarea")) {
            return `<textarea name='tempData' class="w-100">${values[i].value}</textarea>`;
        }
        return '<input ' + ` value = "${values[i].value}" `
    })
    return src;
}

export default {
    convertHtmlToPdf,
    templateTextConverter,
    getTemplateData
};