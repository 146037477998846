import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Feeschedules = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();


  const feesTypes = [
    { label: 'Normal', value: "normal" },
    { label: 'CoPay', value: "coPay" },
    { label: 'Out Of Network', value: "outOfNetwork" },
    { label: 'Fixed Benifit', value: "fixedBenifit" },
    { label: 'Manual Blue Book', value: "manualBlueBook" },
  ]

  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Fee Schedules");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 20,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "hospitalId",
        type: "relateAutoComplete",
        placeholder: "Practice ID",
        label: "Practice ID",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Practice ID",
        derivedValue: "hospitalId=undefined",
        capitalizeTableText: false,
        className:'text-capitalize-first-letter',
        sortable: true,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "hospitalID",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Hospital",
        searchApi: apiCalls.hospitals,
        isMultiple: false,
        textAlign: "left",
        show: true,
        filter: false,
        field: "hospitalId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        className:'text-capitalize-first-letter',
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        // fieldType: "Link",
        style: {
          maxWidth: '500px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
          textOverflow: 'ellipsis',
        },
        tDisplay: true,
      },
      {
        name: "type",
        type: "dropDown",
        placeholder: "Type",
        label: "Type",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Type",
        derivedValue: "type=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "defaultFeeSchedule",
        type: "checkbox",
        placeholder: "Default FeeSchedule",
        label: "Default FeeSchedule",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Default FeeSchedule",
        derivedValue: "defaultFeeSchedule=undefined",
        capitalizeTableText: true,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "defaultFeeSchedule",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        textAlign: "center",
        show: true,
        style: {},
        fieldType: 'dropDown',
        options: [
          { label: 'True', value: true, color: 'success' },
          { label: 'False', value: false, color: 'danger' },
          { label: 'False', value: undefined, color: 'danger' }],
        field: "defaultFeeSchedule",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "fieldType": "Actions",
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "sortable": false,
        displayInSettings: true,

      }
    ];
    return data;
  };


  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "hospitalId",
        value: "",
        type: "relateAutoComplete",
        placeholder: "Practice ID",
        label: "Practice ID",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "hospitalId",
        derivedValue: "hospitalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "fullHospitalName",
        fieldType: "relateAutoComplete",
        controllerName: "hospitals",
        searchApi: apiCalls.hospitals,
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "hospitalId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        header: "Description",
        width: 45,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "description",
        required: true,
        mobile: true,
        displayInSettings: true,
        addFormOrder: 2,
        editFormOrder: 2,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },

      {
        name: "type",
        type: "dropDown",
        placeholder: "Type",
        label: "Type",
        header: "type",
        width: 45,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        options: feesTypes,
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "type",
        required: true,
        mobile: true,
        displayInSettings: true,
        addFormOrder: 3,
        editFormOrder: 3,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "hidden",
        type: "checkbox",
        placeholder: "Hidden",
        label: "Hidden",
        derivedValue: "hidden=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "hidden",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        show: true,
        disabled: true,
        mobile: true,
        globalSearchField: "true",
        required: false,
        displayInSettings: true,
        // fieldType: "dropDown",
        addFormOrder: 7,
        editFormOrder: 7,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "useGlobalFees",
        type: "checkbox",
        placeholder: "Use Global Fees",
        label: "Use Global Fees",
        derivedValue: "useGlobalFees=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "useGlobalFees",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        show: true,
        disabled: true,
        mobile: true,
        globalSearchField: "true",
        required: false,
        displayInSettings: true,
        // fieldType: "dropDown",
        addFormOrder: 8,
        editFormOrder: 8,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "defaultFeeSchedule",
        type: "radio",
        placeholder: "Default FeeSchedule",
        label: "Default FeeSchedule",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "defaultFeeSchedule=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "defaultFeeSchedule",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ],
        value: false,
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      }
    ]
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "type",
        type: "text",
        placeholder: "Type",
        label: "Type",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Type",
        derivedValue: "type=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hidden",
        type: "checkbox",
        placeholder: "Hidden",
        label: "Hidden",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Hidden",
        derivedValue: "hidden=hidden=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hidden",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "hidden",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "useGlobalFees",
        type: "checkbox",
        placeholder: "Use Global Fees",
        label: "Use Global Fees",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Use Global Fees",
        derivedValue: "useGlobalFees=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "useGlobalFees",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "useGlobalFees",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "electronicId",
        type: "text",
        placeholder: "Electronic Id",
        label: "Electronic Id",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Electronic Id",
        derivedValue: "electronicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "electronicId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "electronicId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.feeSchedules}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={false}
          PrintRequired={isEditPermission}
          // globalSearch={'Display Name/Email'}
          // type='Feeschedules'
          // apiUrl={apiCalls.Feeschedules}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.feeschedules}
          globalSearch={'Description / Type'}
          displayName="Fee Schedules"
          type="Fee Schedules"
          routeTo={apiCalls.feeSchedules}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.feeSchedules}
          apiUrl={apiCalls.feeSchedules}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="feeSchedules"
          apiUrl={apiCalls.feeSchedules}
        />
      ) : null}
    </span>
  );
};

export default Feeschedules;
