import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";


export default function DoSignature(props) {

    const signatureRef = useRef(null);

    useEffect(() => {
        if (!props.disabled) {
            setSignatureFun(props.value);
        }
    }, []);

    const handleSignatureChange = (e) => {
        const canvas = signatureRef?.current?.getCanvas();
        const dataURL = canvas.toDataURL('image/png', 1.0); // Use a fixed DPI
        props.onChange(dataURL);
    };

    const setSignatureFun = (e) => {

        props.onChange(e ? e : "");

        if (e) {
            const img = new Image();
            img.src = e;
            img.onload = () => {
                const canvas = signatureRef.current.getCanvas();
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0);
            };
        } else {
            signatureRef.current.clear();
        }
    };

    return <div>

        <div className="d-flex justify-content-between">
            <label>{props.item.label ? props.item.label : props.item.name}</label>
            {!props.disabled && <i className="pi pi-sync ml-5" onClick={() => setSignatureFun()}></i>}
        </div>
        {!props.disabled ?
            <div style={{ border: '2px solid black', display: 'inline-block' }}>
                <SignatureCanvas
                    ref={signatureRef}
                    penColor="blue"
                    canvasProps={{ width: 400, height: 150 }}
                    onEnd={handleSignatureChange}
                    disabled={props.disabled}
                    onBegin={handleSignatureChange}
                // backgroundColor="yellow"
                // clearOnResize={true}
                // throttle={30}
                />
            </div>
            : <div style={{ backgroundColor: props.disabled ? '#dedede' : 'white', width: 400, height: 160, border: '2px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{props.value && <img class="mb-2 w-100 h-100" src={props.value} style={{ objectFit: 'contain' }} />}</div>}
    </div>

}